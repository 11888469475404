<template>
  <div>
    <b-form
      ref="form"
      lazy-validation
    >
      <!-- Input session -->
      <div class="row">
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group>
                <label for="input-id">ID nhân sự:</label>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model="generalInfo.id"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group>
                <label>Chức vụ:</label>
                <b-form-select
                  required
                  class="select-style"
                  v-model="generalInfo.position.selected"
                  :options="generalInfo.position.options"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group v-if="generalInfo.position.selected === 3">
                <template>
                  <label>Khu vực:
                    <span class="text-danger">*</span>
                  </label>
                </template>
                <b-form-select
                  size="sm"
                  class="select-style"
                  v-model="generalInfo.selectedArea"
                  :options="generalInfo.listArea"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >-- Khu vực --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback>Vui lòng chọn khu vực</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                v-else-if="generalInfo.position.selected === 2"
                class="required-control"
              >
                <label>Vùng: </label>
                <b-form-select
                  size="sm"
                  class="select-style"
                  v-model="generalInfo.selectedRegion"
                  :options="generalInfo.listRegion"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >-- Vùng --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback>Vui lòng chọn khu vực</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                v-else
                class="mb-0"
              >
                <label> Cửa hàng: </label>
                <Autosuggest
                  :model="generalInfo.searchStore"
                  :suggestions="generalInfo.filteredOptions"
                  :placeholder="'cửa hàng'"
                  :limit="10"
                  @select="onSelectedStore($event)"
                  @change="onInputChange($event)"
                  suggestionName="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group>
                <label>Nhân viên phụ trách:</label>
                <b-form-input
                  size="sm"
                  placeholder="Nhập nhân viên"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- Row 2 -->
      <div class="row">
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group class="required-control">
                <label>Tên nhân sự: </label>
                <b-form-input
                  size="sm"
                  v-model="generalInfo.fullName"
                  aria-describedby="input-fullName-live-feedback"
                  placeholder="Nhập tên nhân sự"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group class="required-control">
                <label>Mã nhân viên:</label>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã nhân viên"
                  v-model="generalInfo.code"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <label style="font-weight: 500px"> Ngày sinh: </label>
              <b-input-group class="mb-3">
                <date-picker
                  placeholder="Chọn ngày sinh"
                  class="form-control form-control-sm"
                  :config="generalInfo.dpConfigs.date"
                  v-model="generalInfo.birthDay"
                ></date-picker>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <div class="col-md-3">
          <b-row class="my-1">
            <b-col>
              <b-form-group id="input-group-active">
                <label for="select-status">Trạng thái:</label>
                <b-form-select
                  required
                  class="select-style"
                  v-model="generalInfo.active.selected"
                  :options="generalInfo.active.options"
                  aria-describedby="input-active-live-feedback"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- Row 3 -->
      <b-row>
        <b-col>
          <b-form-group>
            <label>SĐT:</label>
            <b-form-input
              style="width: -webkit-fill-available"
              size="sm"
              v-model="generalInfo.mobile"
              placeholder="Nhập số điện thoại"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label>Email:</label>
            <b-form-input
              size="sm"
              v-model="generalInfo.email"
              placeholder="Nhập email"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <label style="font-weight: 500px">Danh sách cửa hàng:</label>
            <b-input-group>
              <vue-multi-select
                style="margin-bottom: 2rem"
                v-model="generalInfo.listStoreId"
                :options="generalInfo.listStores"
                placeholder="Chọn cửa hàng"
                option-label="name"
                @input="onChangeListStore()"
              ></vue-multi-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label>Bộ phận:</label>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="generalInfo.selectedDepartment"
              :options="generalInfo.listDepartment"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null">-- Chọn bộ phận --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <div
          class="col-md-12"
          v-show="generalInfo.listTransferStoreForms.length"
        >
          <p class="mb-1">Lịch sử yêu cầu chuyển cửa hàng:</p>
          <b-row>
            <b-table
              :items="generalInfo.listTransferStoreForms"
              :fields="generalInfo.fields"
              sort-by="createdAt"
              bordered
              hover
              :busy="generalInfo.loadingTransferStoreForm"
              class="mt-7"
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="10"
                ></vcl-table>
              </template>

              <template v-slot:cell(code)="row">
                <a v-bind:href="getLinkDoc(row.item)">
                  <span>
                    {{ row.item.code }}
                  </span>
                </a>
              </template>
              <template v-slot:cell(status)="row">
                <StatusForm
                  :status="row.item.status"
                  :timestamp="row.item?.approvedAt"
                  :performer="row.item?.approvedByName"
                />
              </template>
              <template v-slot:cell(createdAt)="row">
                <p>
                  {{ row.item.createdByName }} - {{ row.item.createdAt }}
                </p>
              </template>
            </b-table>
          </b-row>
        </div>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Danh mục:">
            <b-input-group>
              <vue-multi-select
                v-model="generalInfo.selectedCates"
                :options="categories"
                placeholder="Chọn danh mục"
                option-label="name"
              ></vue-multi-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import VueMultiSelect from 'vue-simple-multi-select';
import { VclTable } from 'vue-content-loading';
import datePicker from 'vue-bootstrap-datetimepicker';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import StatusForm from '@/view/components/transfer-store-forms/StatusForm.vue';

export default {
  props: ['generalInfo', 'categories'],
  created() {},
  data() {
    return {};
  },
  components: {
    datePicker,
    VueMultiSelect,
    Autosuggest,
    StatusForm,
    VclTable,
  },
  methods: {
    onSelectedStore(data) {
      this.$emit('onSelectedStore', data);
    },
    onInputChange(data) {
      this.$emit('onInputChange', data);
    },
    onChangeListStore() {
      this.$emit('onChangeListStore');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="css" scoped>
.center-label label {
  margin-bottom: 0px;
}

.multi-select-wrapper {
  flex: 1 1 auto;
}

.form-group label {
  font-weight: 500;
}
</style>