<template>
  <div>
    <!-- Input session -->
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Số tiền giảm trừ gia cảnh: </label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.personalAllowance"
            placeholder="Nhập số tiền giảm trừ gia cảnh"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Giảm trừ bản thân: </label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.selfAllowance"
            placeholder="Nhập số tiền giảm trừ bản thân"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Người phụ thuộc: </label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.dependent"
            placeholder="Nhập số người phụ thuộc"
            class="text-right"
          />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="mb-3">
          <label> Ngày vào làm việc: </label>
          <date-picker
            placeholder="Chọn ngày vào làm việc"
            class="form-control-sm"
            :config="dpConfigs.date"
            v-model="generalInfo.commencementDate"
          ></date-picker>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Row 2 -->
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Đóng thuế thu nhập cá nhân</label>
          <b-form-select
            class="select-style"
            v-model="generalInfo.personalIncomeTax"
            :options="personalIncomeTaxOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Cửa hàng chính</label>
          <Autosuggest
            :model="generalInfo.employeeStoreId"
            :suggestions="generalInfo.employeeStoreOptions"
            :placeholder="'cửa hàng'"
            :limit="10"
            @select="onSelectEmployeeStore($event)"
            @change="onChangeEmployeeStore($event)"
            suggestionName="name"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Giờ công chuẩn</label>
          <b-form-input
            v-model="generalInfo.standardWorkingHour"
            size="sm"
            placeholder="Nhập giờ công chuẩn"
            class="input-style text-right"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Ngày công chuẩn</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.standardWorkingDay"
            placeholder="Nhập ngày công chuẩn"
            class="text-right"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Lương cơ bản</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.positionSalary"
            placeholder="Nhập mức lương cơ bản"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Lương BHXH</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.insurancePaidSalary"
            placeholder="Nhập mức phụ cấp xăng xe"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Khấu trừ công đoàn</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.unionDues"
            placeholder="Nhập mức khấu trừ công đoàn"
            class="text-right"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp vị trí</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.positionAllowance"
            placeholder="Nhập mức phụ cấp vị trí"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp chuyên cần</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.attendanceAllowance"
            placeholder="Nhập mức phụ cấp xăng xe"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp kiêm nhiệm</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.dualJobAllowance"
            placeholder="Nhập mức phụ cấp kiêm nhiệm"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp xăng xe</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.transportationAllowance"
            placeholder="Nhập mức phụ cấp xăng xe"
            class="text-right"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp công tác</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.collaborateAllowance"
            placeholder="Nhập mức phụ cấp công tác"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp tác phong</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.beautyAllowance"
            placeholder="Nhập mức phụ cấp tác phong"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Phụ cấp khách hàng</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.happyCustomerAllowance"
            placeholder="Nhập mức phụ cấp khách hàng"
            class="text-right"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Khấu trừ BHXH</label>
          <BInputNumber
            size="sm"
            v-model="generalInfo.shuInsuranceDeduction"
            placeholder="Nhập mức khấu trừ BHXH"
            class="text-right"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';

export default {
  components: {
    Autosuggest,
    BInputNumber,
  },
  props: ['generalInfo'],
  data() {
    return {
      personalIncomeTaxOptions: [
        { value: 1, text: 'Có đóng thuế' },
        { value: 0, text: 'Không đóng thuế' },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
    };
  },
  methods: {
    onSelectEmployeeStore(data) {
      this.$emit('onSelectEmployeeStore', data);
    },
    onChangeEmployeeStore(data) {
      this.$emit('onChangeEmployeeStore', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group label {
  font-weight: 500;
}
</style>